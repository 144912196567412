export const CountryRegionData = [
    { name: 'Austria', shortCode: 'AT' },
    { name: 'Belgium', shortCode: 'BE' },
    { name: 'Bulgaria', shortCode: 'BG' },
    { name: 'Croatia', shortCode: 'HR' },
    { name: 'Cyprus', shortCode: 'CY' },
    { name: 'Czech Republic', shortCode: 'CZ' },
    { name: 'Denmark', shortCode: 'DK' },
    { name: 'Estonia', shortCode: 'EE' },
    { name: 'Finland', shortCode: 'FI' },
    { name: 'France', shortCode: 'FR' },
    { name: 'Germany', shortCode: 'DE' },
    { name: 'Hungary', shortCode: 'HU' },
    { name: 'Iceland', shortCode: 'IS' },
    { name: 'Ireland', shortCode: 'IE' },
    { name: 'Italy', shortCode: 'IT' },
    { name: 'Latvia', shortCode: 'LV' },
    { name: 'Liechtenstein', shortCode: 'LI' },
    { name: 'Lithuania', shortCode: 'LT' },
    { name: 'Luxembourg', shortCode: 'LU' },
    { name: 'Malta', shortCode: 'MT' },
    { name: 'Netherlands', shortCode: 'NL' },
    { name: 'Norway', shortCode: 'NO' },
    { name: 'Poland', shortCode: 'PL' },
    { name: 'Portugal', shortCode: 'PT' },
    { name: 'Romania', shortCode: 'RO' },
    { name: 'Slovakia', shortCode: 'SK' },
    { name: 'Slovenia', shortCode: 'SI' },
    { name: 'Spain', shortCode: 'ES' },
    { name: 'Sweden', shortCode: 'SE' },
    { name: 'Switzerland', shortCode: 'CH' }
];
